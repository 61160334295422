.input_field label {
  font-family: Roobert;
  font-size: 14px;
  font-weight: 400;
  line-height: 15.17px;
  text-align: left;
}

.input_field {
  margin-bottom: 15px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  font-size: 14px;
}

.input_field input {
  font-family: 'Spezia';
  font-size: 12;
  padding: 5px 10px;
  border-radius: 5px;
  border-width: 1px;
}

.input_field textarea{
  font-family: 'Spezia';
  font-size: 12;
  padding: 5px 10px;
  border-radius: 5px;
  border-width: 1px;
  resize: none;
  height: 50;
}

.input_field ::placeholder {
  font-family: Spezia;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
  color: #d0cec8;
}

.error {
  font-family: Roobert;
  font-size: 14px;
  font-weight: 400;
  line-height: 15.17px;
  text-align: left;
  color: red;
}

input:focus { 
  outline-width: 0.5px;
  outline-color: #113431;
  border-color: #113431;
}

textarea:focus { 
  outline-width: 0.5px;
  outline-color: #113431;
  border-color: #113431;
}
