.main {
  background: #f5f1e5;
  padding: 26px 30px 47px 30px;
  font-family: Spezia;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.61px;
  text-align: left;
}
.main-title {
  font-family: Roobert;
  font-size: 20px;
  font-weight: 500;
  line-height: 19.61px;
  text-align: left;
}
ol.how_works {
  padding-left: 25px;
  list-style-type: none;
  counter-reset: letter;
}
ol.how_works li {
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
  counter-increment: letter;
}

ol.how_works li::before {
  content: counter(letter) '. ';
  margin-right: 10px;
  flex-shrink: 0;
}
.form {
  margin-top: 21px;
  display: grid;
  column-gap: 12px;
  grid-template-columns: repeat(2, 1fr); /* Две колонки одинаковой ширины */
  grid-template-areas:
    'field1 field2'
    'field3 field3'
    'field4 field4'
    'field5 field6'
    'field7 field7'
    'field8 field8';
}

.form > :nth-child(1) {
  grid-area: field1;
}
.form > :nth-child(2) {
  grid-area: field2;
}
.form > :nth-child(3) {
  grid-area: field3;
}
.form > :nth-child(4) {
  grid-area: field4;
}
.form > :nth-child(5) {
  grid-area: field5;
}
.form > :nth-child(6) {
  grid-area: field6;
}
.form > :nth-child(7) {
  grid-area: field7;
}
.form > :nth-child(8) {
  grid-area: field8;
}

.input_field {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input_field label {
  font-family: Roobert;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.17px;
  text-align: left;
}

.input_field input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  margin: 0;
  padding: 0;
  overflow-y: scroll;
}
html::-webkit-scrollbar {
  display: none; /* Для браузеров на основе Webkit (Safari, Chrome) */
}

html {
  -ms-overflow-style: none; /* Для IE и Edge */
  scrollbar-width: none; /* Для Firefox */
  background: #f5f1e5;
}
