.bgr {
  position: relative;
  width: 100%;
  height: 292px;
  overflow: hidden;
}
.bgr_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.logo {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
}
.slogan {
  max-width: 296px;
  position: absolute;
  bottom: 10px;
  right: 20px;
  color: white;
  font-family: Roobert;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.28px;
  text-align: right;
  z-index: 2;
}
.never {
  font-family: Jannon 10 Pro;
  font-size: 22px;
  font-style: italic;
  font-weight: 400;
  line-height: 27.57px;
  text-align: right;
}
