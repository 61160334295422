.footer{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 14px;
    box-sizing: border-box;  
    height: 60px;
    border-top: 0.5px solid #000;
    background: rgba(245, 241, 229, 1);
}
.footerBtn{
    margin: 0 auto;
    width: 227px;
    padding: 9px;
    box-sizing: border-box;  
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px 0px;
    border: 0.5px solid #000;
    background: var(--Emerald, #113431);
    cursor: pointer;
}
.textBtn{
    color: #FFF;
    font-family: Mabry;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.nextBtn{
    display: flex;
    width: 20px;
    height: 10px;
}